import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  right: 24px;
  min-height: 30px;
  padding: 0 16px 0 32px;
`

export const NumBox = styled.div`
  position: relative;
  z-index: 2;
  min-width: 22px;
  height: 22px;
  border-radius: 25px;
  background-color: ${orange.extra};

  span {
    font-family: "Sora", Helvetica, sans-serif;
    font-size: 11px;
  }
`

export const Line = styled.div`
  position: relative;

  &::after {
    content: "";
    height: 70%;
    width: 1px;
    position: absolute;
    background-color: ${grayscale[200]};
    z-index: 1;
    top: 26px;
    left: 11px;
  }

  div {
    div {
      div:first-child ${Card} {
        border-top-left-radius: 12px;
      }
      div:last-child ${Card} {
        border-bottom-left-radius: 12px;
      }
    }
  }
`

export const Div = styled.div`
  margin: 32px 0;

  @media ${device.tablet} {
    margin-top: 10px;
    margin-bottom: 16px;
  }
`
